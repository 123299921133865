<template>
  <v-container fluid>
      <v-row>
          <v-col>
            <v-dialog
            v-model="dialog"
            width="700"
            
            transition="dialog-bottom-transition"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs" v-on="on" @click="getSuppliersReference()"><v-icon>mdi-plus</v-icon> زیادکردنی وەرگرتنەوەی قەرز</v-btn>
            </template>
            <v-card>
                <v-toolbar
                dark
                outlined
                :class="(isUpdate?'orange accent-2':'blue accent-2')"
                elevation="1"
                >
                <v-btn
                    icon 
                    @click="dialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn  text @click="isUpdate? update():create()" >
                    <v-icon>{{isUpdate? 'mdi-check':'mdi-plus'}}</v-icon> {{isUpdate? 'نوێکردنەوەی گەڕاندنەوەی قەرز': 'زیادکردنی گەڕاندنەوەی قەرز'}} </v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <v-form ref="return_debt">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" lg="6" sm="12">
                               <v-autocomplete :items="supplierReference" item-text="supplier_name" item-value="supplier_id" dense outlined label="کۆمپانیا" v-model="return_debt.supplier_id"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" :rules="rules.numberRule" prepend-inner-icon="mdi-currency-usd" dense label="بڕی" outlined v-model="return_debt.amount" type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()"  dense label="تێبینی" outlined v-model="return_debt.note"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field @focus="$event.target.select()"
                                        v-model="return_debt.date"
                                        label="بەرواری"
                                        prepend-inner-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="return_debt.date"
                                    @input="menu2 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
            </v-dialog>
            <v-card>
                <v-data-table :headers="headers" :items="return_debts" hide-default-footer  :items-per-page="20"  :loading="loading" no-data-text="هیچ گەڕاندنەوەیەک نیە  ">
                    <template v-slot:top>
                        <v-text-field @focus="$event.target.select()"
                        v-model="table.search"
                        label="گەڕان"
                        class="mx-4"
                        ></v-text-field>
                    </template>
                    <template v-slot:[`item.amount`]="{ item }">
                        {{'$'+item.amount.toLocaleString()}}
                    </template>
                    <template v-slot:[`item.discount`]="{ item }">
                        {{'$'+item.discount.toLocaleString()}}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon" >
                        <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon" @click="return_debt = {...removeProperty(item, ['member_name', 'customer_name'])}; dialog=true;getSuppliersReference()">mdi-pencil</v-icon>
                        <v-icon large class="ml-2" v-else>mdi-pencil</v-icon>
                        </v-hover>
                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon" >
                            <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon" @click="remove(item.return_debt_id)">mdi-delete</v-icon>
                            <v-icon large class="ml-2" v-else >mdi-delete</v-icon>
                        </v-hover>
                    </template>
                </v-data-table>
                <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfReturnDebt / 20)"  v-model="table.page"></v-pagination>
            </v-card>
          </v-col>
      </v-row>
      <notification/>
  </v-container>
</template>

<script>
export default {
    data() {
        return {
            table: {
                page: 1,
                numberOfReturnDebt: 0,
                search: ''
            },
            dialog: false,
            menu2: false,
            supplierReference: [],
            return_debt: {
                return_debt_id: 0,
                supplier_id: 0,
                amount: 0,
                note: '',
                date: new Date().toISOString().split("T")[0],
            },
            loading: false,
            return_debts: [],
            headers: [
                {text: 'ژمارەی وەصل', value: 'return_debt_id', align: 'center'},
                {text: 'کۆمپانیا', value: 'supplier_name', align: 'center'},
                {text: 'بڕی', value: 'amount', align: 'center'},
                {text: 'لە بەرواری', value: 'date', align: 'center'},
                {text: 'تێبینی', value: 'detail', align: 'center'},
                {text: 'کردارەکان', value: 'actions', align: 'center'},
            ],
            rules: {
                stringRule: [
                    (v) => {
                        return ( v != '' || 'تکایە ئەم خانەیە پڕبکەرەوە')
                    }
                ],
                numberRule: [
                    (v) => {
                        return ( v >= 0 || 'ژمارەکە پێویستە لە 0 زیاتربێت')
                    }
                ]
            }
        }
    },
    methods: {
        async getreturn_debts(){
            try {
                this.loading = true
                const {data} = await this.axios.get('/return_debt', {params:{page:this.table.page, search: this.table.search}})
                
                data.return_debt.forEach(return_debt => {
                    return_debt.date  = new Date(return_debt.date).toISOString().split("T")[0]
                });
                this.return_debts = data.return_debt
                this.table.numberOfReturnDebt = data.count
                this.loading = false
            } catch (error) {
                console.log(error);
                this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
            }
        },
        async getSuppliersReference(){
            try {
                const {data} = await this.axios.get('/supplier/forReference')
                this.supplierReference = data
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
            }
        },
        async create(){
            if (this.$refs.return_debt.validate()) {
                try {
                    await this.axios.post('/return_debt', this.return_debt)
                    this.reset()
                    this.getreturn_debts()
                } catch (error) {
                    this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                }
            }
        },
        async update() {
            if (this.$refs.return_debt.validate()) {
                try {
                    await this.axios.patch('/return_debt/'+ this.return_debt.return_debt_id, this.return_debt)
                    this.getreturn_debts()
                    this.reset()
                    this.dialog = false
                } catch (error) {
                    this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                }
            }
        },
        remove(id){
            this.$confirm('ئایا دڵنیایی لە سڕینەوەی ئەم وەصلە؟', 'دڵنیابوونەوە', 'question').then(async () => {
                try {
                    await this.axios.delete('/return_debt/'+id)
                    this.getreturn_debts()
                } catch (error) {
                    this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                }
            })
        },
        reset(){
            this.return_debt = {
                return_debt_id: 0,
                supplier_id: 0,
                amount: 0,
                note: '',
                member_id: 0,
                date: new Date().toISOString().split("T")[0],
                discount: 0
            }
            this.$refs.return_debt.resetValidation();
        },
        removeProperty({...obj} = {}, fields){
            for (let i = 0; i < fields.length; i++)  delete obj[fields[i]]
            return obj
        }
    },
    created() {
        this.getreturn_debts()
    },
    watch: {
        dialog(val){
            if (!val) {
                this.memberReference = []
                this.supplierReference = []
                this.reset()
            }
        },
        'table.search': function () {
            this.getreturn_debts()
        },
        'table.page': function () {
            this.getreturn_debts()
        }
    },
    computed: {
        isUpdate(){
            return this.return_debt.return_debt_id
        }
    }
}
</script>

<style>

</style>